import styles from './Table.module.scss';
import { useRef } from 'react';
import { forEach, map } from 'utils/react';
import { useOnChange } from 'utils/hooks';
import PropTypes from 'prop-types';

const MobileTable = ({ children, className, ...otherProps }) => {
  const thead = useRef(null);
  const tbodies = useRef([]);
  const mobile = useRef(null);
  const captionRef = useRef(null);

  useOnChange(() => {
    tbodies.current = [];

    forEach(children, child => {
      if (child.type === 'thead')
        thead.current = child;
      else if (child.type === 'caption')
        captionRef.current = child;
      else if (child.type === 'tbody')
        tbodies.current.push(child);
    });
  }, [children]);

  useOnChange(() => {
    if (!tbodies.current) {
      mobile.current = null;
      return;
    }

    let theadRow;
    if (thead.current) {
      const { children: theadChildren } = thead.current.props;

      forEach(theadChildren, row => {
        theadRow = row;
      });
    }

    const headers = theadRow
      ? map(theadRow.props.children, th => th)
      : [];

    mobile.current = tbodies.current.map((tbody, index) => {
      const { children: tbodyChildren, ...tbodyProps } = tbody.props;
      const rows = map(tbodyChildren, (row, rowIndex) => {
        const {
          children: rowChildren,
          className: rowClassName,
          ...rowProps
        } = row.props;

        return (
          <div key={rowIndex} role="row" {...rowProps} className={`${styles.tr} ${rowClassName || ''}`}>
            {map(rowChildren, (cell, cellIndex) => {
              if (cell.props['data-empty'])
                return null;

              const { colSpan, scope, headers: _headers, className = '', ...cellProps } = cell.props;

              if (cell.props.colSpan === headers.length)
                return (
                  <div
                    {...cellProps}
                    role="cell"
                    style={{ width: '100%' }}
                    className={`${styles.td} ${styles.noTitle} ${className}`}
                  >
                    {cellProps.children}
                  </div>
                );

              const th = headers[cellIndex];
              const name = th && <div className={styles.name}>{th.props.children}</div>;
              const cellChildren = <div className={styles.value}>{cellProps.children}</div>;

              return (
                <div
                  {...cellProps}
                  role={cell.type === 'th' ? 'rowheader' : 'cell'}
                  className={`${cell.type === 'th' ? styles.th : styles.td} ${name ? '' : styles.noTitle} ${className}`}
                >
                  {name}{cellChildren}
                </div>
              );
            })}
          </div>
        );
      });

      return (
        <div key={index} {...tbodyProps} role="rowgroup">
          {rows}
        </div>
      );
    });
  }, [thead.current, tbodies.current]);

  let caption = null;

  if (captionRef.current) {
    const { children: captionChildren, ...captionProps } = captionRef.current.props;
    caption = (
      <div {...captionProps}>
        {captionChildren}
      </div>
    );
  }

  return (
    <div
      {...otherProps}
      className={`${styles.table} mobile ${className || ''}`}
      role="table"
    >
      {caption}
      {mobile.current}
    </div>
  );
};

MobileTable.propTypes = {
  className: PropTypes.string,
};

export default MobileTable;
