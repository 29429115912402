import styles from './Table.module.scss';
import MobileTable from './MobileTable';
import PropTypes from 'prop-types';
import { useIsMobileViewport } from 'utils/hooks';

const Table = ({ children, className, mobileLabel, ...otherProps }) => {
  const isMobile = useIsMobileViewport();

  return (
    <>
      {isMobile
        ? (
          <MobileTable {...otherProps} className={className} aria-label={mobileLabel}>
            {children}
          </MobileTable>
        )
        : (
          <table {...otherProps} className={`${styles.table} ${className || ''}`}>
            {children}
          </table>
        )
      }
    </>
  );
};

Table.propTypes = {
  className: PropTypes.string,
  mobileLabel: PropTypes.string,
};

export default Table;
